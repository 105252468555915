<!--折扣率管理-->
<template>
  <div class="panel banner">
    <div class="serch_box">
      <el-form :inline="true" class="user-search fixed_form">
        <el-form-item label="Dsicount Rate Type" size="">
          <template>
            <el-select
              v-model.trim="form.discountType"
              placeholder="Please Choose"
              size=""
            >
              <el-option
                v-for="item in contractTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Enterprise Name">
          <template>
            <el-select v-model="enterpriseNameCode" popper-class="enterpriseNameClass" filterable placeholder="Please enter company name" @change="changeEnterpriseFn">
              <el-option
                v-for="item in EnterpriseList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Contract No" class="contract-no">
          <template>
            <el-input
              v-model.trim="form.contractNo"
              size=""
              placeholder="Pleas enter the Contract No"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                style="width:30px"
                @click="showSku"
              />
            </el-input>
          </template>
        </el-form-item>
        <el-form-item label="Approval Status" size="">
          <template>
            <el-select
              v-model.trim="form.approvedState"
              placeholder="Please Choose"
              size=""
            >
              <el-option
                v-for="item in approvalStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <!-- <el-form-item label="Enterprise Name" size="" class="contract-no">
          <template>
            <el-input
              v-model.trim="form.enterpriseName"
              size=""
              placeholder="Pleas enter the Enterprise Name"
            />
          </template>
        </el-form-item> -->
      </el-form>
      <el-button
        class="btn-common"
        type=""
        @click="offBtn"
      >
        Reset
      </el-button>
      <el-button
        type="primary"
        class="btn-common"
        icon="el-icon-search"
        @click="searchBtn"
      >
        Search
      </el-button>
      <div class="add-discount-rate-class">
        <el-button type="primary" icon="el-icon-plus" @click="addBtn">
          Add Discount Rate
        </el-button>
      <!-- <el-button type="" @click="exportBtn"> Export Excel </el-button> -->
      </div>
    </div>
    <div class="panel-body">
      <el-table
        v-loading="loading"
        :data="tableList"
        fit
        style="width: 100%"
        :header-cell-style="{ background: '#e8f1fe' }"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column label="NO." width="140" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>
              {{ scope.row.id || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Discount Rate Type" min-width="220">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in contractTypeList"
              v-show="item.value == scope.row.discountType"
              :key="index"
            >
              {{ item.label || '--' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Contract No"
          width="210"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.contractNo || '--' }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="Enterprise Name"
          width="140"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.enterpriseName || '--' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          label="Submitter ERP"
          min-width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.creator || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Approval Status" min-width="140">
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.approvedState !== 0 && scope.row.approvedState !== ''
              "
            >
              <div
                v-for="(item, index) in approvalStatusList"
                v-show="item.value == scope.row.approvedState"
                :key="index"
              >
                {{ item.label || '--' }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Latest Submission Time"
          :show-overflow-tooltip="true"
          min-width="180"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.modified | filterDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Operate" width="120" fixed="right">
          <template slot-scope="scope">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link font_blue">
                Modify
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span @click="handleUpdate(scope.row)">Export</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="handleDel(scope.row)">Delete</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span @click="updata(scope.row)">Modify</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog title="Choice Contract No" :visible.sync="dialogSKU" width="50%">
      <Delotal @handleCurrentChanges="handleCurrentChanges" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSKU = false">Cancel</el-button>
        <el-button
          
          type="primary"
          @click="updateDatas('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入API接口
import {
  getrecord,
  deleterecord,
  downloadTemplat,
  oddExport
} from '@/api/user/list';
import Delotal from '../contractManage/conttemplat.vue';
import { mapGetters } from 'vuex';
import dateFormate from '../../utils/date'
import { getEnterpriseName } from '@/api/enterprise/enterprise';
export default {
  // 引入局部刷新
  inject: ['reload'],
  components: {
    Delotal
  },
  data() {
    return {
      emptyImgSrc: '',
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0
      },
      // 搜索条件
      form: {
        discountType: '',
        contractNo: '',
        approvedState: '',
        // enterpriseName: ''
      },
      // 后端获取表格信息
      tableList: [],
      loading: false,
      contractTypeList: [
        { value: '1', label: 'Category-General Discount Rate' },
        { value: '2', label: 'Category-Exclusive Discount Rate' },
        { value: '3', label: 'SKU-General Discount Rate' },
        { value: '4', label: 'SKU-Exclusive Discount Rate' }
      ],
      approvalStatusList: [
        { value: 1, label: 'Waiting for approval' },
        { value: 2, label: 'Approved' },
        { value: 3, label: 'Rejected' }
      ],
      stateList: [
        { value: 0, label: 'Valid' },
        { value: 1, label: 'Invalid' }
      ],
      dialogSKU: false,
      multipleSelections: '',
      EnterpriseList: [],
      enterpriseNameCode: '',
    };
  },
  computed: {
    ...mapGetters({
      isResourceFlag: 'getIsResourceFlag'
    })
  },
  filters: {
    filterDate(dateStr){
      return dateFormate(dateStr,'yyyy-MM-dd hh:mm:ss')
    }
  },
  watch: {},
  created() {
    // 初始化表数据
    this.getFirstData();
    this.getEnterpriseNameData();
    this.emptyImgSrc = '';
  },
  methods: {
    // 切换企业名称，回显合同号
    changeEnterpriseFn(val) {
      this.form.contractNo = val;
    },
    // 根据企业名称查询 合同号
    getEnterpriseNameData() {
      getEnterpriseName().then(res => {
        if (res.code === 200) {
          var list = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.contractNo != null) {
                list.push({
                  value: element.contractNo,
                  label: element.enterpriseName,
                  id: element.id
                });
              }
            }
            this.EnterpriseList = list;
          } else {
            this.EnterpriseList = [];
          }
        }
      });
    },
    showSku() {
      this.dialogSKU = true;
    },
    handleCurrentChanges(val) {
      console.log(val);
      this.multipleSelections = val;
    },
    updateDatas() {
      console.log(this.multipleSelections);

      this.form.contractNo = this.multipleSelections.contractNo;
      this.dialogSKU = false;
    },
    // 获取页面数据 bannerName,contractNo,page,size
    getFirstData() {
      getrecord({
        discountRecordDTO: Object.assign(
          this.form
        ),
        pageNo: this.listQuery.pageCode,
        pageSize: this.listQuery.pageSize
      }).then(res => {
        if (res.success && res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total  = res.data.totalCount;
        } else {
          this.tableList = [];
          this.listQuery.total  = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 新增
    addBtn() {
      this.$router.push({
        name: 'New discount rate',
        path: '/discountManage/addDiscount'
      });
    },
    exportBtn() {
      downloadTemplat().then(response => {
        this.isExcel('xlsx', 'Export Excel', response);
      });
    },

    isExcel(type, name, data) {
      const link = document.createElement('a');
      const blob = new Blob([data]);
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${name}.` + type);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getFirstData();
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.reload();
      this.enterpriseNameCode = '';
    },
    // 列表-修改按钮
    handleUpdate(row) {
      oddExport(row).then(response => {
        this.isExcel('xlsx', row.created + 'Discount-Rate', response);
      });
    },
    updata(row) {
      row.flang = '1';
      this.$router.push({
        name: 'New discount rate',
        path: '/discountManage/addDiscount',
        query: {id:row.id,flang:row.flang,discountType:row.discountType,contractNo:row.contractNo,isResourceFlag: this.isResourceFlag,isApprovedState: row.approvedState }
      });
    },
    // 列表-删除按钮
    handleDel(row) {
      this.$confirm('Are you confirm to delete?', 'warning', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      })
        .then(() => {
          deleterecord(row).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'successfully delete',
                type: 'success'
              });
              this.getFirstData();
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          });
        })
        .catch(function() {});
    }
  }
};
</script>
<style lang="less" scoped>
.banner {
  background: white;
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
  .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 40px;
  }
}
.heard_box {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  div {
    label {
      margin-right: 5px;
    }
  }
}
.serch_box {
  width: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 30px;
  }
  .add-discount-rate-class{
    margin-top: 10px;
  }
  .btn-common{
    width: 100px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.panel-body {
  padding: 0 16px;
}
.avatar-uploader .el-upload:hover {
  border-color: #2262ff;
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-button {
  // padding: 0 12px !important;
  // width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}
::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
.font_blue {
  color: #2262ff;
  cursor: pointer;
}

.contract-no {
  margin-left: 30px;
  ::v-deep .el-form-item__content {
    margin-top: 3px;
  }
}
</style>
